<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs"/>
    <div class="content-box">
      <el-row class="table-box">
        <el-row type="flex" justify="space-between">
          <el-row></el-row>
          <el-row class="right-btn m-bottom-5">
            <el-button @click="visible = true; action = 'insert'" class="bg-gradient">新增消费项</el-button>
          </el-row>
        </el-row>
        <el-table v-loading="loading" :data="tableData" :stripe="true" border fit>
          <el-table-column label="分类">
            <template slot-scope="scope">
              {{ scope.row.categoryCode | filterCategory(that) }}
            </template>  
          </el-table-column>
          <el-table-column prop="name" label="消费项名称"></el-table-column>
          <el-table-column label="价格" align="right">
            <template slot-scope="scope">
              ￥{{ scope.row.price }}
            </template>
          </el-table-column>
          <el-table-column prop="state" label="状态">
            <template slot-scope="scope">
              <el-switch @change="state => updateStatus(scope.row, state)" v-model="scope.row.state" 
                active-value="ENABLED" inactive-value="DISABLED" inactive-text="停用" active-text="启用"></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="handleEdit(scope.row)" type="text">编辑</el-button>
              <el-button @click="handleRemove(scope.row.id)" type="text">
                <el-link type="danger">删除</el-link>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="handleCurrPage"/>
      </el-row>
    </div>
    <el-dialog title="新增消费项" :close-on-click-modal="false" :visible.sync="visible"
               width="550px" :before-close="handleClose">
      <el-form ref="form" :model="ruleForm" :rules="rules">
        <el-form-item label="分类" prop="categoryCode">
          <el-select class="width-280" v-model="ruleForm.categoryCode" placeholder="请选择分类">
            <el-option v-for="(val, key) in dictData['consumption-category']" :key="key" :label="val" :value="key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="ruleForm.name" class="width-280" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="价格" prop="price">
          <el-input-number class="width-280" v-model="ruleForm.price" :min="0" label="价格"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button class="bg-gradient" @click="handleSave">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getPage, insertConsumptionItem,
  removeConsumptionItem } from "@/api/pms/accommodationSetting/consumerItemSet";
import { mapState } from 'vuex';
import { getDict } from "@/common/js/common";
export default {
  data () {
    return {
      crumbs: new Map([
        ['PMS'], ['住宿设置'], ['消费项设置']
      ]),
      loading: false,
      tableData: [],
      total: 0,
      limit: 0,
      page: 1,
      visible: false,
      action: 'insert',
      ruleForm: {
        id: '',
        categoryCode: '',
        name: '',
        price: 0,
        state: 'ENABLED'
      },
      rules: {
        categoryCode: [{ required: true, message: '消费分类不能为空！' }],
        name: [{ required: true, message: '消费名称不能为空！' }],
        price: [{ required: true, message: '消费价格不能为空！' }]
      },
      that:this,
    }
  },
  computed: {
    ...mapState(['hotelInfo', 'dictData'])
  },
  mounted() {
    this.initForm = { ...this.ruleForm };
    this.limit = sessionStorage.getItem('pageSize') * 1;
    getDict(['consumption-category'])
    this.getConsumerItemList()
  },
  methods: {
    // 获取消费项列表
    getConsumerItemList() {
      const query = `?limit=${ this.limit }&page=${ this.page }`,
            params = { hotelId: this.hotelInfo.id };
      getPage(query, params).then(({ success, records, total }) => {
        if (success) {
          this.loading = false
          this.total = total
          this.tableData = records
        }
      })
    },
    // 改变每页数
    changePageNum(num) {
      this.limit= num
      this.getConsumerItemList()
    },
    // 改变当前页
    handleCurrPage(num) {
      this.page = num
      this.getConsumerItemList()
    },
    // 处理编辑
    handleEdit(row) {
      this.visible = true
      this.action = 'modify'
      for (const k in this.ruleForm) {
        k in row && (this.ruleForm[k] = row[k])
      }
    },
    // 切换状态
    updateStatus({ id, hotelId, companyId }, state) {
      const params = { id, state, hotelId, companyId }
      insertConsumptionItem('modify', params).then(({ success }) => {
        if (success) {
          this.$message({ message: `${ state === 'DISABLED' ? '停用' : '启用' }成功！`, type: 'success' })
          this.getConsumerItemList()
        }
      })
    },
    // 保存消费项
    handleSave() {
      this.$refs.form.validate(valid => {
        if (!valid) return
        const params = {
          ...this.ruleForm,
          hotelId: this.hotelInfo.id,
          companyId: this.hotelInfo.storeId
        };
        this.action === 'insert' && delete params.id
        insertConsumptionItem(this.action, params).then(({ success }) => {
          if (success) {
            this.getConsumerItemList()
            this.handleClose()
            this.$message({
              message: `${this.action === 'insert' ? '新增' : '编辑'}成功`, type: 'success'
            })
          }
        })
      })
    },
    // 关闭弹窗
    handleClose() {
      this.visible = false
      this.ruleForm = { ...this.initForm };
    },
    // 删除消费项
    handleRemove(id) {
      this.$confirm('确定删除消费项？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        removeConsumptionItem({ ids: id }).then(({ success }) => {
          if (success) {
            this.$message({ message: '删除成功！', type: 'error' })
            this.getConsumerItemList()
          }
        })
      })
    }
  },
  filters:{
    filterCategory(val,that){
      if(val){
        return that.dictData['consumption-category'][val]
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.cont {
  .el-form{
    padding: 0 0 0 40px;
    ::v-deep .el-form-item__error{ left: 51px }
  }
}
</style>
