import request from "@/api/service";
import qs from 'qs';

const api = {
   page: '/pms/consumptionItem/getPage',
   insert: '/pms/consumptionItem/insert',
   modify: '/pms/consumptionItem/modify',
   remove: '/pms/consumptionItem/remove',
   getList:'/pms/consumptionItem/getList',
   // 收款
   income:'/pms/collectionRefund/add',
   // 收款(异常订单)
   income2:'/pms/nightTrialbookInfo/add',
   removeIncome:'/pms/collectionRefund/remove',
};

export function getPage(query, params) {
   return request({
      url: api.page + query,
      method: 'POST',
      data: params
   })
}

export function insertConsumptionItem(type, params) {
   return request({
      url: api[type],
      method: 'POST',
      data: params
   })
}

export function removeConsumptionItem(params) {
   return request({
      url: api.remove,
      method: 'POST',
      data: qs.stringify(params)
   })
}

export function getListConsumptionItem(params){
   return request({
      url: api.getList,
      method: 'POST',
      data: params,
   })
}

export function setIncome(params,abnormalOrders){
   return request({
      // url: abnormalOrders ? api.income2 : api.income,
      url: api.income,
      method: 'POST',
      data: params,
   })
}

export function removeIncomelist(params){
   return request({
      url: api.removeIncome+"/"+params,
      method: 'POST',
   })
}